import classNames from "classnames";
import is from "is_js";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef } from "react";
import { Collapse, Nav, Navbar } from "reactstrap";
import { navbarBreakPoint } from "../../config";
import AppContext from "../../context/Context";
import { clientRoutes, tmbRoutes } from "../../routes";
import Flex from "../common/Flex";
import Logo from "./Logo";
import NavbarVerticalMenu from "./NavbarVerticalMenu";
import ToggleButton from "./ToggleButton";
import { connect } from "react-redux";

const NavbarVertical = ({ navbarStyle, user }) => {
  const navBarRef = useRef(null);

  const {
    showBurgerMenu,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
  } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  //Control Component did mount and unmounted of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add("navbar-vertical-collapsed");
  }

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add("windows");
    }
    if (is.chrome()) {
      HTMLClassList.add("chrome");
    }
    if (is.firefox()) {
      HTMLClassList.add("firefox");
    }
    return () => {
      HTMLClassList.remove("navbar-vertical-collapsed-hover");
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add("navbar-vertical-collapsed-hover");
      }, 100);
    }
  };
  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames("navbar-vertical navbar-glass", {
        [`navbar-${navbarStyle}`]: navbarStyle !== "transparent",
      })}
      light
    >
      <Flex align="center">
        <ToggleButton
          isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
        />
        <Logo at="navbar-vertical" width={110} />
      </Flex>

      <Collapse
        navbar
        isOpen={showBurgerMenu}
        className="scrollbar"
        innerRef={navBarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          clearTimeout(time);
          HTMLClassList.remove("navbar-vertical-collapsed-hover");
        }}
      >
        <Nav navbar vertical>
          <NavbarVerticalMenu
            routes={
              user.isAuth && user.roles.includes("ROLE_TMB")
                ? tmbRoutes
                : clientRoutes
            }
          />
        </Nav>
      </Collapse>
    </Navbar>
  );
};

NavbarVertical.protoTypes = {
  navbarStyle: PropTypes.string,
};

NavbarVertical.defaultProps = {
  navbarStyle: "transparent",
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(NavbarVertical);
