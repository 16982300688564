import React from "react";
import { connect } from "react-redux";
import { signOut } from "../redux/actions";
import { useCookies } from "react-cookie";

const TempNoGroupInfo = ({ signOut }) => {
  const removeCookie = useCookies(["AUTH_TOKEN"])[2];

  const handleLogut = () => {
    signOut();
    removeCookie("AUTH_TOKEN", { path: "/" });
  };

  return (
    <>
      <p>Brak widoku dla danej grupy użytkownika.</p>
      <button className="button" onClick={() => handleLogut()}>
        Wyloguj
      </button>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(TempNoGroupInfo);
