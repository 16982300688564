export const navbarBreakPoint = "xl"; // Vertical navbar breakpoint
export const topNavbarBreakpoint = "lg";
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  isTopNav: false,
  isVertical: true,
  get isCombo() {
    return this.isVertical && this.isTopNav;
  },
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: "$",
  isNavbarVerticalCollapsed: false,
  navbarStyle: "transparent",
  dateFormat: "DD-MM-YYYY",
  dateFormatPicker: "dd-MM-yyyy",
};
export default { navbarBreakPoint, topNavbarBreakpoint, settings };
