import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({
  canView,
  redirectPath,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      canView ? (
        <Component props={props} />
      ) : (
        <Redirect
          to={{
            pathname: redirectPath,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default ProtectedRoute;
