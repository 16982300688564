import React from "react";
import { Col, Row } from "reactstrap";
import { version } from "../../../package.json";

const Footer = () => (
  <footer>
    <Row
      noGutters
      className="justify-content-between text-center fs--1 mt-4 mb-3"
    >
      <Col sm="auto" />
      <Col sm="auto">
        <p className="mb-0 text-600">v{version}</p>
      </Col>
    </Row>
  </footer>
);

export default Footer;
