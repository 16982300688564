import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { signIn } from "../../redux/actions";
import { useCookies } from "react-cookie";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import axios from "axios";
import { JWT_LOGIN_URL } from "../../helpers/endpoints";
import {
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  CustomInput,
  Label,
} from "reactstrap";

const LoginForm = ({ hasLabel, signIn }) => {
  const setCookie = useCookies(["AUTH_TOKEN"])[1];
  // JWT PAYLOAD TASK
  const setUserCookie = useCookies(["CLIENT_ID"])[1];
  const setWorkerCookie = useCookies(["WORKER_ID"])[1];
  const setTokenRfCookie = useCookies(["REFRESH_TOKEN"])[1];
  // State
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsDisabled(true);
    const loginData = {
      email,
      password,
    };
    axios
      .post(JWT_LOGIN_URL, loginData)
      .then(({ data: { data, token, refresh_token } }) => {
        setCookie("AUTH_TOKEN", token, { path: "/" });
        setTokenRfCookie("REFRESH_TOKEN", refresh_token, { path: "/" });
        // JWT PAYLOAD TASK
        if (data.clientPersonId)
          setUserCookie("CLIENT_ID", data.clientPersonId, { path: "/" });
        if (data.tmbPersonId)
          setWorkerCookie("WORKER_ID", data.clientPersonId, { path: "/" });
        signIn({
          email: data.email,
          roles: data.roles,
          clientPerson: data.clientPersonId
            ? `/api/client_people/${data.clientPersonId}`
            : null,
          tmbPerson: data.clientPersonId
            ? `/api/tmb_people/${data.clientPersonId}`
            : null,
        });
      })
      .catch(({ response }) => {
        setIsDisabled(false);
        toast.error(
          response ? response.data.message : "Wystąpił nieznany błąd"
        );
        setEmail("");
        setPassword("");
      });
    // TODO
    // FAKE LOGIN
    // setTimeout(function() {
    //   let newName = "david";
    //   setCookie("name", newName, { path: "/" });
    //   signIn();
    //   toast.success(`Zalogowano jako ${email}`);
    // }, 1000);
  };

  useEffect(() => {
    setIsDisabled(!email || !password);
  }, [email, password]);

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Adres email</Label>}
        <Input
          placeholder={!hasLabel ? "Email address" : ""}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Hasło</Label>}
        <Input
          placeholder={!hasLabel ? "Password" : ""}
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <CustomInput
            id="customCheckRemember"
            label="Zapamiętaj mnie"
            checked={remember}
            onChange={({ target }) => setRemember(target.checked)}
            type="checkbox"
          />
        </Col>
      </Row>
      <FormGroup>
        <Button color="danger" block className="mt-3" disabled={isDisabled}>
          Zaloguj
        </Button>
      </FormGroup>
    </Form>
  );
};

LoginForm.propTypes = {
  setRedirect: PropTypes.func,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
};

LoginForm.defaultProps = {
  layout: "basic",
  hasLabel: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (user) => dispatch(signIn(user)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(LoginForm);
