export const signIn = (user) => {
  return {
    type: "SIGN_IN",
    payload: user,
  };
};

export const signOut = () => {
  return {
    type: "SIGN_OUT",
  };
};

export const setEditingClient = (id, name) => {
  return {
    type: "SET_EDIT_CLIENT",
    payload: { id, name },
  };
};
export const setEditingDepartment = (id, name) => {
  return {
    type: "SET_EDIT_DEPARTMENT",
    payload: { id, name },
  };
};
export const setEditingDevice = (id, name) => {
  return {
    type: "SET_EDIT_DEVICE",
    payload: { id, name },
  };
};
export const setEditingPerson = (id, name) => {
  return {
    type: "SET_EDIT_PERSON",
    payload: { id, name },
  };
};
export const setEditingTicket = (id, name) => {
  return {
    type: "SET_EDIT_TICKET",
    payload: { id, name },
  };
};
export const setReloadTrue = () => {
  return {
    type: "SET_RELOAD_TRUE",
  };
};
export const setReloadFalse = () => {
  return {
    type: "SET_RELOAD_FALSE",
  };
};
