import React, { useContext } from "react";
import NavbarTop from "../components/navbar/NavbarTop";
import NavbarVertical from "../components/navbar/NavbarVertical";
import Footer from "../components/footer/Footer";
import { connect } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import AppContext from "../context/Context";
import { getPageName } from "../helpers/utils";
import loadable from "@loadable/component";

const Clients = loadable(() => import("../components/tmb/Clients/Clients"));
const Devices = loadable(() => import("../components/tmb/Devices/Devices"));
const Service = loadable(() => import("../components/tmb/Service/Service"));
const ClientDetails = loadable(() =>
  import("../components/tmb/Clients/ClientDetails")
);
const DepartmentDetails = loadable(() =>
  import("../components/tmb/Clients/Departments/DepartmentDetails")
);
const PersonDetails = loadable(() =>
  import("../components/tmb/Clients/Persons/PersonDetails")
);
const DeviceDetails = loadable(() =>
  import("../components/tmb/Clients/Devices/DeviceDetails")
);
const DepartmentPersonDetails = loadable(() =>
  import("../components/tmb/Clients/Departments/Persons/PersonDetails")
);
const Users = loadable(() => import("../components/tmb/Users/Users"));
const ClientPersonDetails = loadable(() =>
  import("../components/tmb/Users/ClientPersonDetails")
);
const TmbPersonsDetails = loadable(() =>
  import("../components/tmb/Users/TmbPersonDetails")
);
const DevicesDeviceDetails = loadable(() =>
  import("../components/tmb/Devices/Devices/DeviceDetails")
);
const DepartmentDevice = loadable(() =>
  import("../components/tmb/Clients/Departments/Devices/DeviceDetails")
);
const TicketDetails = loadable(() =>
  import("../components/tmb/Service/Tickets/TicketDetails")
);

const TmbDashboardLayout = () => {
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);

  const isKanban = getPageName("kanban");

  return (
    <div className={isFluid || isKanban ? "container-fluid" : "container"}>
      {isVertical && (
        <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />
      )}
      <div className="content">
        <NavbarTop />
        <Switch>
          {/* CLIENTS */}
          <Route path="/" exact component={Clients} />
          <Route path="/client-details" component={ClientDetails} />
          <Route path="/department-details" component={DepartmentDetails} />
          <Route path="/person-details" component={PersonDetails} />
          <Route path="/device-details" component={DeviceDetails} />
          {/* DEPARTMENTS */}
          <Route
            path="/departments/device-details"
            component={DepartmentDevice}
          />
          {/* DEVICES */}
          <Route path="/devices" exact component={Devices} />
          <Route
            path="/devices/device-details"
            component={DevicesDeviceDetails}
          />
          <Route
            path="/department-person-details"
            component={DepartmentPersonDetails}
          />
          {/* SERVICE */}
          <Route path="/service" exact component={Service} />
          <Route path="/ticket-details" exact component={TicketDetails} />
          {/* USERS */}
          <Route path="/users" component={Users} />
          <Route
            path="/client-person-details"
            component={ClientPersonDetails}
          />
          <Route path="/tmb-person-details" component={TmbPersonsDetails} />
          <Redirect to="/errors/404" />
        </Switch>

        {!isKanban && <Footer />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(TmbDashboardLayout);
