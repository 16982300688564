const editDepartmentReducer = (state = null, action) => {
    switch (action.type) {
      case "SET_EDIT_DEPARTMENT":
        return {
          ...action.payload
        };
      default:
        return state;
    }
  };
  
  export default editDepartmentReducer;
  