const reloadDataReducer = (state = false, action) => {
  switch (action.type) {
    case "SET_RELOAD_TRUE":
      return true;
    case "SET_RELOAD_FALSE":
      return false;
    default:
      return state;
  }
};

export default reloadDataReducer;
