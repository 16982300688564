const editClientReducer = (state = null, action) => {
    switch (action.type) {
      case "SET_EDIT_CLIENT":
        return {
          ...action.payload
        };
      default:
        return state;
    }
  };
  
  export default editClientReducer;
  