import React from 'react';
import Logo from '../../navbar/Logo';
import LoginForm from '../LoginForm';

import AuthCardLayout from '../../../layouts/AuthCardLayout';

const Login = () => {
  return (
    <AuthCardLayout
    >
      <Logo width={140}/>
      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Login;
