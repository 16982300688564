import React from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LogoutContent = ({ titleTag: TitleTag }) => {
  return (
    <>
      <TitleTag>Zostałeś wylogowany</TitleTag>
      <Button
        tag={Link}
        color="primary"
        size="sm"
        className="mt-3"
        to={`/auth/login`}
      >
        <FontAwesomeIcon
          icon="chevron-left"
          transform="shrink-4 down-1"
          className="mr-1"
        />
        Powrót do logowania
      </Button>
    </>
  );
};

LogoutContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string,
};

LogoutContent.defaultProps = {
  layout: "basic",
  titleTag: "h4",
};

export default LogoutContent;
