import { combineReducers } from "redux";
import userReducer from "./user";
import editClientReducer from "./editClient";
import editDepartmentReducer from "./editDepartment";
import reloadDataReducer from "./reloadData";
import editDeviceReducer from "./editDevice";
import editPersonReducer from "./editPerson";
import editTicketReducer from "./editTicket";

const allReducers = combineReducers({
  user: userReducer,
  editClient: editClientReducer,
  editDepartment: editDepartmentReducer,
  editDevice: editDeviceReducer,
  editPerson: editPersonReducer,
  editTicket: editTicketReducer,
  reloadData: reloadDataReducer,
});

export default allReducers;
