export const tmbRoutes = [
  {
    name: "Klienci",
    to: "/",
    exact: true,
    icon: "building",
  },
  {
    name: "Urządzenia",
    to: "/devices",
    exact: true,
    icon: "print",
  },
  {
    name: "Abonamenty",
    to: "#",
    exact: true,
    icon: "handshake",
  },
  {
    name: "Serwis",
    to: "/service",
    exact: true,
    icon: "tools",
  },
  {
    name: "Oferty",
    to: "#",
    exact: true,
    icon: "copy",
  },
  {
    name: "Umowy",
    to: "#",
    exact: true,
    icon: "file-signature",
  },
  {
    name: "Pracownicy",
    to: "#",
    exact: true,
    icon: "id-card",
  },
  {
    name: "CRM",
    to: "#",
    exact: true,
    icon: "funnel-dollar",
  },
  // {
  //   name: "Produkty",
  //   to: "/products",
  //   exact: true,
  //   icon: "boxes",
  // },
  {
    name: "Administracja",
    icon: "lock-open",
    children: [
      { to: "/users/client-persons", name: "Użytkownicy" },
      // { to: "#", name: "Magazyny" },
    ],
  },
];

export const clientRoutes = [
  {
    name: "Urządzenia",
    to: "/",
    exact: true,
    icon: "print",
  },
  {
    name: "Oddziały",
    to: "/departments",
    exact: true,
    icon: "building",
  },
  {
    name: "Osoby",
    to: "#",
    exact: true,
    icon: "id-card",
  },
  {
    name: "Abonamenty",
    to: "#",
    exact: true,
    icon: "handshake",
  },
  {
    name: "Serwis",
    to: "/service",
    exact: true,
    icon: "tools",
  },
  {
    name: "Oferty",
    to: "#",
    exact: true,
    icon: "copy",
  },
  {
    name: "Umowy",
    to: "#",
    exact: true,
    icon: "file-signature",
  },
];
