import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Login from "./Login";
import Logout from "./Logout";

const AuthCardRoutes = ({ match: { url } }) => (
  <Switch>
    <Route path={`${url}/login`} exact component={Login} />
    <Route path={`${url}/logout`} exact component={Logout} />

    {/*Redirect*/}
    <Redirect to="/errors/404" />
  </Switch>
);

AuthCardRoutes.propTypes = { match: PropTypes.object.isRequired };

export default withRouter(AuthCardRoutes);
