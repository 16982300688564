import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { CloseButton, Fade } from "../components/common/Toast";
import ProtectedRoute from "../hoc/ProtectedRoute";
import TmbDashboardLayout from "./TmbDashboardLayout";
import ClientDashboardLayout from "./ClientDashboardLayout";
import TempNoGroupInfo from "./TempNoGroupInfo";
import AuthCardRoutes from "../components/auth/card/AuthCardRoutes";
import ErrorLayout from "./ErrorLayout";

const Layout = ({ user }) => {
  const [dashboard, setDashboard] = useState(TempNoGroupInfo);
  useEffect(() => {
    // Funkcja zwraca komponent to wyrenederowania na podstawie roli użytkownika
    const checkUserRole = (roles) => {
      let result = TempNoGroupInfo;
      if (user.isAuth && roles.includes("ROLE_TMB"))
        result = TmbDashboardLayout;
      if (user.isAuth && roles.includes("ROLE_CLIENT"))
        result = ClientDashboardLayout;
      return result;
    };
    let componentToRender = checkUserRole(user.roles);
    setDashboard(componentToRender);
  }, [user.roles, user.isAuth]);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/errors" component={ErrorLayout} />
        <ProtectedRoute
          canView={!user.isAuth}
          redirectPath="/"
          path="/auth"
          component={AuthCardRoutes}
        />
        <ProtectedRoute
          canView={user.isAuth}
          redirectPath="/auth/login"
          component={dashboard}
        />
      </Switch>
      <ToastContainer
        transition={Fade}
        closeButton={<CloseButton />}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Layout);
