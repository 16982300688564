import React, { useEffect } from "react";
import { connect } from "react-redux";
import { signIn } from "./redux/actions";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./layouts/Layout";
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";

import "react-toastify/dist/ReactToastify.min.css";
import "react-datetime/css/react-datetime.css";
import "react-image-lightbox/style.css";

const App = ({ signIn }) => {
  const [cookies] = useCookies(["AUTH_TOKEN", "CLIENT_ID"]);

  useEffect(() => {
    // JWT PAYLOAD TASK
    if (cookies.AUTH_TOKEN) {
      const token = cookies.AUTH_TOKEN;
      const clientId = cookies.CLIENT_ID;
      const decoded = jwt_decode(token);
      const user = {
        email: decoded.username,
        roles: decoded.roles,
        clientPerson: clientId ? `/api/client_people/${clientId}` : null,
      };
      signIn(user);
    }
  }, [cookies.AUTH_TOKEN, cookies.CLIENT_ID, signIn]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (user) => dispatch(signIn(user)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(App);
