import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GRAPHQL_URL } from "./endpoints";
import { onError } from "@apollo/client/link/error";

const errorLink = onError(({ graphqlErrors }) => {
  if (graphqlErrors) {
    graphqlErrors.mpa(({ message }) => {
      alert(`Graphql error ${message}`);
    });
  }
});

const link = from([errorLink, new HttpLink({ uri: GRAPHQL_URL })]);

const getCookieValue = (name) =>
  document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getCookieValue("AUTH_TOKEN");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export const getQuery = (query) =>
  new Promise((resolve) => {
    client
      .query({
        query: gql`
      query GetData {
          ${query}
      }
    `,
      })
      .then((result) => resolve(result.data));
  });
