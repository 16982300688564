import React from 'react';
import LogoutContent from '../LogoutContent';

import AuthBasicLayout from '../../../layouts/AuthBasicLayout';

const Logout = () => {
  return (
    <AuthBasicLayout
    >
        <LogoutContent layout="card" titleTag="h3" />
    </AuthBasicLayout>
  );
};

export default Logout;
