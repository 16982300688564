import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import { connect } from "react-redux";
import { signOut } from "../../redux/actions";
import team3 from "../../assets/img/profile.jpg";
import Avatar from "../common/Avatar";
import { useCookies } from "react-cookie";

const ProfileDropdown = ({ signOut }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const removeCookie = useCookies(["AUTH_TOKEN"])[2];
  const removeClient = useCookies(["CLIENT_ID"])[2];
  const removeWorker = useCookies(["WORKER_ID"])[2];

  const handleLogut = () => {
    signOut();
    removeCookie("AUTH_TOKEN", { path: "/" });
    removeClient("CLIENT_ID", { path: "/" });
    removeWorker("WORKER_ID", { path: "/" });
  };

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className="pr-0">
        <Avatar src={team3} />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem onClick={() => handleLogut()}>Wyloguj</DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ProfileDropdown);
